<template>
  <div class="main">
    <header-t />
    <nav2 />
    <div class="cont">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item v-if="type === 'notice'"
          >公告</el-breadcrumb-item
        >
        <el-breadcrumb-item v-if="type === 'news'">资讯</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="item mar-bottom">
        <el-row :gutter="20">
          <el-col :span="16">
            <el-card shadow="nerve" class="card">
              <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="资讯频道" name="0">
                  <div class="tabs-cont">
                    <div class="banner-wrap">
                      <Swiper :swiperSlides="focusData"></Swiper>
                    </div>
                    <!-- <el-card shadow="nerve" class="card"> -->
                    <!-- <el-tabs v-model="activeName" @tab-click="handleClick"> -->

                    <div class="art-top" v-for="item in rows" :key="item.rows">
                      <div class="art-intro" @click="goDetail(item.id)">
                        <div class="art-intro-left">
                          {{ item.title }}
                        </div>
                        <div class="art-intrp-right">
                          <div class="icon">
                            <i class="el-icon-time"></i>
                            <span class="icontime">{{ item.publishTime }}</span>
                          </div>
                          <!-- <div class="icon" style="color:#666666"><i class="el-icon-collection-tag"></i>
                                  <span class="icontime">开课通知</span>
                                </div> -->
                        </div>
                      </div>
                    </div>
                    <!-- </el-tabs> -->

                    <!-- </el-card> -->
                  </div>
                </el-tab-pane>
                <el-tab-pane label="操作手册" name="7">
                  <div class="tabs-cont">
                    <div class="art-top" v-for="item in rows" :key="item.rows">
                      <div class="art-intro" @click="goDetail(item.id)">
                        <div class="art-intro-left">
                          {{ item.title }}
                        </div>
                        <div class="art-intrp-right">
                          <div class="icon">
                            <i class="el-icon-time"></i>
                            <span class="icontime">{{ item.publishTime }}</span>
                          </div>
                          <!-- <div class="icon" style="color:#666666"><i class="el-icon-collection-tag"></i>
                                  <span class="icontime">开课通知</span>
                                </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="行业动态" name="3">
                  <div class="tabs-cont">
                    <div class="art-top" v-for="item in rows" :key="item.rows">
                      <div class="art-intro" @click="goDetail(item.id)">
                        <div class="art-intro-left">
                          {{ item.title }}
                        </div>
                        <div class="art-intrp-right">
                          <div class="icon">
                            <i class="el-icon-time"></i>
                            <span class="icontime">{{ item.publishTime }}</span>
                          </div>
                          <!-- <div class="icon" style="color:#666666"><i class="el-icon-collection-tag"></i>
                                  <span class="icontime">开课通知</span>
                                </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="政策法规" name="4">
                  <div class="tabs-cont">
                    <div class="art-top" v-for="item in rows" :key="item.rows">
                      <div class="art-intro" @click="goDetail(item.id)">
                        <div class="art-intro-left">
                          {{ item.title }}
                        </div>
                        <div class="art-intrp-right">
                          <div class="icon">
                            <i class="el-icon-time"></i>
                            <span class="icontime">{{ item.publishTime }}</span>
                          </div>
                          <!-- <div class="icon" style="color:#666666"><i class="el-icon-collection-tag"></i>
                                  <span class="icontime">开课通知</span>
                                </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="开课通知" name="5">
                  <div class="tabs-cont">
                    <div class="art-top" v-for="item in rows" :key="item.rows">
                      <div class="art-intro" @click="goDetail(item.id)">
                        <div class="art-intro-left">
                          {{ item.title }}
                        </div>
                        <div class="art-intrp-right">
                          <div class="icon">
                            <i class="el-icon-time"></i>
                            <span class="icontime">{{ item.publishTime }}</span>
                          </div>
                          <!-- <div class="icon" style="color:#666666"><i class="el-icon-collection-tag"></i>
                                  <span class="icontime">开课通知</span>
                                </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="报名指南" name="6">
                  <div class="tabs-cont">
                    <div class="art-top" v-for="item in rows" :key="item.rows">
                      <div class="art-intro" @click="goDetail(item.id)">
                        <div class="art-intro-left">
                          {{ item.title }}
                        </div>
                        <div class="art-intrp-right">
                          <div class="icon">
                            <i class="el-icon-time"></i>
                            <span class="icontime">{{ item.publishTime }}</span>
                          </div>
                          <!-- <div class="icon" style="color:#666666"><i class="el-icon-collection-tag"></i>
                                  <span class="icontime">开课通知</span>
                                </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>
              <div class="pagination">
                <Pagination
                  :total="search.total"
                  :page.sync="search.page"
                  :limit.sync="search.size"
                  @pagination="getDataList"
                />
              </div>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card
              v-for="item in focuDataMin"
              :key="item.id"
              shadow="nerve"
              class="card"
            >
              <div @click="goDetail(item.id)" class="panel recommend">
                <img class="top_img" :src="item.url" alt="" />
                <div class="r_tit_bg">
                  <p class="r_title">{{ item.title }}</p>
                </div>
              </div>
            </el-card>
            <!-- <el-card shadow="nerve" class="card top20">
              <div class="panel recommend">
                <img class="top_img" src="https://www.wlmqcol.com/assets/v2/img/article/article_banner_2.jpg?version=8.8.0" alt="">
                <p class="r_title">2022年现场岗位八大员第五期新取证培训通知</p>
              </div>
            </el-card> -->
            <el-card shadow="nerve" class="card">
              <div class="panel">
                <div class="tit">
                  <h3>编辑推荐</h3>
                </div>
                <div class="body">
                  <ul class="dynamic">
                    <li
                      v-for="item in recommendData"
                      :key="item.id"
                      @click="goDetail(item.id)"
                    >
                      {{ item.title }}
                    </li>
                  </ul>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import HeaderT from '@/components/HeaderT'
import Nav2 from '@/components/Nav2'
import Footer from '@/components/Footer'
import Pagination from '@/components/Pagination'
import { getHotspot, getArticle } from '@/api/index'
import { getImgDataList} from "@/api/common";
import Swiper from '@/components/VueAwesomeSwiper'
export default {
  name: 'noticeList',
  components: {
    HeaderT,
    Nav2,
    Footer,
    Pagination,
    Swiper
  },
  data() {
    return {
      info: {},
      noticeId: null,
      activeName: '0',
      search: {
        page: 1,
        size: 10,
        total: 0 //分页
      },
      // noticeType: 1,
      rows: [],
      type: '', // 类型
      sliders0: [
      ],
      sliders2: [
      ],
      sliders: [
        // 轮播图
        /* {
          id:1,
          url:'https://www.wlmqcol.com/assets/v2/img/article/article_banner_1.jpg?version=8.8.0',
          title: '1关于规范水利水电工程施工企业主要负责人、项目负责人和专职安全生产管理人员安全生产考核合格证书管理工作的通知'
        },
        {
          url:'https://www.wlmqcol.com/assets/v2/img/article/article_banner_2.jpg?version=8.8.0',
        },
        {
          url:'https://www.wlmqcol.com/assets/v2/img/article/article_banner_4.jpg?version=8.8.0',
        },
        {
          url:'https://www.wlmqcol.com/assets/v2/img/article/article_banner_4.jpg?version=8.8.0',
        },
        {
          url:'https://www.wlmqcol.com/assets/v2/img/article/article_banner_5.jpg?version=8.8.0',
        } */
      ],
      focusData: [], // 焦点
      focuDataMin: [], // 右侧两个推荐位置
      recommendData: [], // 推荐
      categoryId: 0, //0      所有信息（资讯频道页使用）3	行业动态4	政策法规5	招生通知6	报名指南7	操作手册
      bannerdata:[]
    }
  },
  created() {
    console.log(this.$route.query.type)
    if (this.$route.query.type) {
      this.type = this.$route.query.type
    }
    if(this.$route.query.tab) {// tab
      this.activeName = '5'
      this.categoryId = 5
    }
  },
  mounted() {
    
    this.getDataHot(2)
    this.getDataList()
    this.getbannerData()
  },
  methods: {
    getbannerData() { 
      getImgDataList(2).then(res =>{
        if(res.data.code === 0) {
          this.sliders0 = res.data.data.rows.map((item)=>{
            return item.imgUrl
          });
          return getImgDataList(3)
          
        }
      }).then(res =>{
        if(res.data.code === 0) {
          this.sliders2 = res.data.data.rows.map((item)=>{
            return item.imgUrl
          });
          this.getDataHot(0)
        }
      })
    },
    handleClick(tab) {
      // console.log(tab.name)
      this.categoryId = tab.name
      this.rows = []
      this.getDataList()
      
    },
    // 获取资讯-热点
    getDataHot(typeId) {
      getHotspot({ type: typeId }).then((res) => {
        // type:0 轮播图; 1 热门焦点; 2 编辑推荐
        if (res.data.code === 0) {
          if (typeId === 0) {
            // debugger

            if (res.data.data.rows.length > 0) {
              res.data.data.rows.forEach((item, index) => {
                Object.assign(item, { url: this.sliders0[index] })
              })
              // 处理轮播图数据
              for (let i = 0; i <= 4; i++) {
                this.focusData.push(res.data.data.rows[i])
              }

              //右侧推荐用轮播图数据6、7条
              let _arry = [{...res.data.data.rows[5],url:this.sliders2[0]},
              {...res.data.data.rows[6],url:this.sliders2[1]}
              ]
              
                this.focuDataMin= _arry;
            }
          }
          if (typeId === 1) {
            // this.focusData = res.data.data.rows
          }
          if (typeId === 2) {
            this.recommendData = res.data.data.rows
          }
          // this.rows = res.data.data.rows
        }
      })
    },
    getDataList() {
      getArticle(Object.assign(this.search, { categoryId: this.categoryId })).then((res) => {
        console.log(res)
        if (res.data.code === 0) {
          this.rows = res.data.data.rows
          this.search.total = res.data.data.total
          // this.$message({
          //   type:'success',
          //   message:res.data.message
          // })
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    },
    goDetail(id) {
      this.$router.push({
        path: '/article',
        query: {
          newsId: id,
          type: 'news'
        }
      })
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.main {
  height: 100%;
  background-color: #f5f5f5;
  .cont {
    width: 1170px;
    margin: 20px auto;
    .item {
      margin-top: 20px;
      .card {
        /* .art-top{
          border-top: 1px solid #ddd;
          margin-top: 1px; */
        .art-intro {
          position: relative;
          text-align: left;
          display: flex;
          justify-content: space-around;
          /* margin-top: 30px; */
          /* width: 825px; */
          height: 55px;
          line-height: 55px;
          /* border-top: 1px solid #ddd; */
          border-bottom: 1px solid #ddd;
          font-size: 14px;
          .art-intro-left {
            width: 65%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: pointer;
            color: #333;
            text-decoration: none;
            a {
              cursor: pointer;
              color: #333;
              text-decoration: none;
              outline: none;
              margin-left: 5px;
            }
          }
          .art-intro-left:hover {
            color: #b7090a;
          }
          .art-intrp-right {
            width: 35%;
            /* display: flex;
            justify-content: space-around; */
            .icon {
              width: 45%;
              font-size: 16px;
              color: #999999;
              float: right;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              .icontime {
                font-size: 14px;
                margin-left: 10px;
              }
            }
          }
        }
        /* } */
        .pagination {
          margin-top: 30px;
        }
      }
      .card:nth-of-type(n + 2) {
        margin-top: 20px;
      }
      .top20 {
        margin-top: 20px;
      }
      .panel {
        .tit {
          text-align: left;
          line-height: 30px;
          border-bottom: 1px solid #f5f5f5;
        }
        .body {
          padding: 15px 0;
          .media-left {
            display: table-cell;
            vertical-align: top;
            padding-right: 15px;
            .avatar-md {
              width: 60px;
              height: 60px;
              border-radius: 50%;
              margin: 5px;
            }
          }
          .media-member {
            display: table-cell;
            vertical-align: top;
            .avatar-md {
              float: left;
              width: 36px;
              height: 36px;
              border-radius: 50%;
              margin: 5px;
            }
          }
          .dynamic {
            margin-top: 5px;
            li {
              text-align: left;
              font-size: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              word-wrap: normal;
              color: #666;
              border-bottom: 1px #eee dashed;
              height: 30px;
              line-height: 30px;
              margin: 5px 0;
            }
            li:hover {
              color: #b7090a;
              cursor: pointer;
            }
          }
          .media-right {
            display: table-cell;
            vertical-align: top;
            font-size: 14px;
            .title {
              margin: 10px 0 10px 0;
              text-align: left;
            }
          }
        }
      }
      .recommend {
        position: relative;
        .r_tit_bg {
          position: absolute;
          background: #eee;
          width: 100%;
          height: 30px;
          padding-top: 12px;
          opacity: 0.7;
          left: 0px;
          bottom: 0px;
          .r_title {
            color: #333;
            text-indent: 10px;
            width: 310px;
            overflow: hidden;
            /* border:1px #f00 solid; */
            text-align: left;
            white-space: nowrap; /* 防止文本换行 */
            overflow: hidden; /* 隐藏超出容器的部分 */
            text-overflow: ellipsis; /* 显示省略号 */
          }
        }

        .top_img {
          width: 100%;
        }
      }
      .recommend:hover {
        cursor: pointer;
      }
    }
    .mar-bottom {
      margin-bottom: 20px;
    }
    .banner-wrap {
      height: 366px;
    }
  }
}
</style>