<template>
  <swiper :options="swiperOption">
    <swiper-slide v-for="(slide, key) in swiperSlides" :key="key">
      <div class="item" @click="handleClick(slide.id)">
        <img :src="slide.url" alt="" />
        <div class="tit-bg">
          <p class="title">{{ slide.title }}</p>
        </div>
      </div>
    </swiper-slide>
    <div class="swiper-pagination" slot="pagination"></div>
  </swiper>
</template>
<script>
export default {
  name: 'carousel',
  // props: ["swiperSlides"], // this.swiperSlides
  props: {
    swiperSlides: {
      type: [Array],
      require: true,
      default: () => ({})
    }
  },
  data() {
    return {
      //官方参数
      swiperOption: {
        autoplay: true,
        setWrapperSize: true,
        observeParents: true,
        pagination: {
          el: '.swiper-pagination'
        }
      }
    }
  },
  mounted() {},
  methods: {
    handleClick(id) {
      console.log('cccc:',id)
      this.$router.push({
        path: '/article',
        query: {
          newsId: id,
          type: 'news'
        }
      })
    }
  }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.item {
  cursor: pointer;
  img {
    width: 100%;
    height: 366px;
  }
  .swiper-pagination {
    /* border:1px #f00 solid; */
    width: 700px;
    text-align: center;
  }
  .tit-bg {
    position: absolute;
    left: 0px;
    width: 100%;
    bottom: 0px;
    background: #eee;
    height: 40px;
    padding-top: 8px;
    opacity: 0.7;
    .title {
      text-indent: 10px;

      color: #333;
      width: 700px;
      overflow: hidden;
      /* border:1px #f00 solid; */
      text-align: left;
      white-space: nowrap; /* 防止文本换行 */
      overflow: hidden; /* 隐藏超出容器的部分 */
      text-overflow: ellipsis; /* 显示省略号 */
    }
  }
}
</style>